<template>
  <v-dialog
    v-model="showDialog"
    :max-width="500"
    persistent
  >
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          Login As
        </common-trs-modal-card-title-content>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <v-row>
          <v-col>
            <div
              style="word-break: break-word;"
            >
              Select Role:
            </div>
            <common-input-autocomplete
              v-model="selectedRole"
              class="mt-2 custom-scroll-1"
              color="primary"
              :label="'Select'"
              item-text="role_display_name"
              item-value="role_name"
              :items="currentRoles"
              hide-details
              @change="selectRole"
            />
          </v-col>
        </v-row>
        <v-row v-if="selectedRole && selectedCaptable !== CONSTS.USER_ROLES.TAPP_ADMIN">
          <v-col>
            <div
              style="word-break: break-word;"
            >
              Select Captable:
            </div>
            <common-input-autocomplete
              v-model="selectedCaptable"
              class="mt-2 custom-scroll-1"
              color="primary"
              :label="'Select'"
              item-text="name"
              item-value="captable_id"
              :search-input.sync="search"
              :items="updatedCaptableList"
              hide-details
              @change="selectCaptable"
            />
          </v-col>
        </v-row>
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  import debounce from 'lodash.debounce'

  export default {
    name: 'SelectUserRole',

    props: {
      roleList: {
        type: Array,
        required: true,
      },
      currentRoles: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      showDialog: false,
      search: '',
      selectedRole: '',
      selectedCaptable: '',
      loading: false,
      updatedCaptableList: [],
    }),

    watch: {
      currentRoles: {
        handler (val) {
          if (val?.length === 1) {
            this.selectedCaptable = val[0]?.role_name
          }
        },
        immediate: true,
      },
      roleList: {
        handler (val) {
          if (val?.length > 0) {
            this.showDialog = true
          }
        },
        immediate: true,
      },
      selectedRole: {
        handler (val) {
          if (val) {
            this.updateCaptableList()
          }
        },
        immediate: true,
      },
      search: {
        handler: debounce(function (val) {
          this.searchCaptable(val)
        }, 500),
      },
    },

    methods: {
      close () {
        this.showDialog = false
      },
      searchCaptable (value) {
        if (value) {
          this.loading = true
          this.updatedCaptableList = this.roleList.filter(obj =>
            obj.role.toLowerCase().includes(value.toLowerCase()))
          this.loading = false
        } else {
          this.updateCaptableList()
        }
      },
      updateCaptableList () {
        this.updatedCaptableList = this.roleList.find((item) => item.role === this.selectedRole)?.orgList || []
      },
      selectCaptable () {
        const selectedCaptable = this.updatedCaptableList.find((item) => item.captable_id === this.selectedCaptable)
        this.$emit('selectCaptable', selectedCaptable, this.selectedRole)
      },
      selectRole () {
        if (this.selectedRole === this.CONSTS.USER_ROLES.TAPP_ADMIN) {
          this.$emit('selectCaptable', {}, this.selectedRole)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .theme--dark.v-card, .theme--dark.v-card > .v-card__text {
    background: var(--v-accent-lighten1) !important;
    color: var(--v-info-base) !important;
  }
</style>

<template>
  <div>
    <common-app-loader :loading="loading" />
    <select-user-role
      :role-list="roleList"
      :current-roles="currentRoles"
      @selectCaptable="onCaptableSelection"
    />
  </div>
</template>
<script>
  import * as captableAdminService from '@/services/tApp-admin-users'
  import SelectUserRole from './components/SelectUserRole'

  export default {
    name: 'AutoLogin',
    components: {
      SelectUserRole,
    },
    data: () => ({
      loading: true,
      loginData: {},
      isTappAdmin: false,
      isHolder: false,
      isSuperAdmin: false,

      roleList: [],
      currentRoles: [],
      userCaptableList: [],
      userData: {},
    }),
    async created () {
      if (this.$route.query.token && (this.$route.query.source !== this.CONSTS.CAPTABLE_SOURCES_TYPES.DL)) { // TAPP flow
        await this.ssoLogin()
      } else {
        this.loginData = this.$route.query.data ? JSON.parse(atob(this.$route.query.data)) : {}
      }
      const source = this.loginData?.source || this.$route.query?.source
      this.$store.dispatch('auth/setSource', source)
      await this.checkUserRole()
    },
    methods: {
      onCaptableSelection (selectedCaptable, selectedRole) {
        const roles = []
        roles.push(selectedRole)
        this.isTappAdmin = roles.includes(this.CONSTS.USER_ROLES.TAPP_ADMIN.toLowerCase())
        this.isHolder = roles.includes(this.CONSTS.USER_ROLES.HOLDER.toLowerCase())
        this.isSuperAdmin = roles.includes(this.CONSTS.USER_ROLES.CAPTABLE_SUPER_ADMIN.toLowerCase())
        const selectedRoleDetails = this.userCaptableList.find((item) => item?.role?.role_name === selectedRole)
        const accessToken = selectedRoleDetails?.access
        const orgList = selectedRoleDetails?.organizations || []
        this.$store.dispatch('auth/setHolderCaptableList', orgList)
        this.$store.dispatch('auth/setRole', roles)
        this.$store.dispatch('auth/setIsDirectLogin', true)
        this.$store.dispatch('auth/setIsSuperAdmin', this.isSuperAdmin)
        if (this.isTappAdmin) {
          this.setV2CaptableAdminData(accessToken, roles)
        } else if (this.isHolder) {
          this.setV2HolderCaptableView(accessToken, selectedCaptable)
        } else {
          this.setV2CaptableOwnerData(accessToken, selectedCaptable, roles)
        }
        this.navigateToView(selectedRole)
      },
      async ssoLogin () {
        try {
          this.loginData = await captableAdminService.ssoLogin(this.$route.query.token)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to login',
          })
        }
      },
      async checkUserRole () {
        const userProfile = this.loginData?.user_profile
        this.userData = userProfile?.user
        if (userProfile?.roles?.length > 0) {
          this.userCaptableList = userProfile.roles

          const roles = userProfile.roles.map((role) => {
            return role?.role?.role_name
          })
          this.currentRoles = userProfile.roles.map((role) => {
            return role?.role
          })
          const allRoleOgrs = []
          if (roles?.length === 1 && (this.userCaptableList[0]?.organizations?.length === 0 || this.userCaptableList[0]?.organizations?.length === 1)) {
            this.onCaptableSelection(this.userCaptableList[0]?.organizations[0], roles[0])
          } else {
            roles.forEach((role) => {
              const orgList = this.userCaptableList?.find((data) => data.role.role_name === role)
              if (orgList?.organizations?.length > 0) {
                const orgData = {
                  role: role,
                  orgList: orgList.organizations,
                }
                allRoleOgrs.push(orgData)
              }
            })
          }
          this.roleList = allRoleOgrs
        } else {
          this.setV1CaptablePortalData(this.loginData)
          this.navigateToView((this.loginData.role).toLowerCase())
        }
      },
      navigateToView (selectedRole) {
        if (this.isTappAdmin) {
          this.$router.push({ name: 'Users.List' })
        } else if ((selectedRole === this.CONSTS.USER_ROLES.HOLDER) || this.isHolder) {
          if (this.loginData.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.DL) {
            this.$router.push({ name: 'Holder.CaptableSummary' })
          } else {
            this.$router.push({ name: 'Holder.Dashboard' })
          }
        } else {
          this.$router.push({ name: 'Captable' })
        }
      },
      setCaptableData (auth, id, organization, user, isCaptableAdmin, role) {
        const selectedOrg = { id: organization?.captable_id, name: organization?.name }
        this.$store.dispatch('auth/setAuthInfo', auth)
        this.$store.dispatch('auth/setCurrentOrganization', selectedOrg)
        this.$store.dispatch('auth/setUser', user)
        this.$store.dispatch('account/setUserProfile', user)
        this.$store.dispatch('auth/setIsCaptableAdmin', isCaptableAdmin)
        this.$store.dispatch('auth/setRole', role)
        this.$store.dispatch('auth/setCurrentFidId', null)
        if (role !== this.CONSTS.USER_ROLES.HOLDER) {
          this.$store.dispatch('auth/setCaptableId', id)
        }
      },
      async setV1CaptablePortalData (data) {
        if (data.role && (data.role).toLowerCase() === (this.CONSTS.USER_ROLES.HOLDER).toLowerCase()) {
          this.isHolder = true
          this.setV1HolderCaptableView(data)
        } else {
          this.setV1CaptableAdminView(data)
        }
      },
      async setV1HolderCaptableView (data) {
        const cyndxToken = { access_token: atob(this.$route.query.token) }
        const holderData = this.$store.getters['auth/holderCaptableList']
        const userData = data.user_profile
        this.$store.dispatch('auth/setUser', userData)
        this.$store.dispatch('account/setUserProfile', userData)
        this.$store.dispatch('auth/setCurrentFidId', data.tenant_id)
        this.$store.dispatch('auth/setRole', (data.role).toLowerCase())
        this.$store.dispatch('auth/setAuthInfo', cyndxToken)
        if (holderData) {
          this.$store.dispatch('auth/setHolderCaptableList', [])
        }
      },
      setV1CaptableAdminView (captableData) {
        const cyndxToken = { external_token: atob(this.$route.query.token) }
        const captableId = captableData && captableData.issuer && captableData.issuer.id
        this.setCaptableData(cyndxToken, captableId, captableData.issuer, captableData.user, false, captableData.role)
      },
      setV2CaptableAdminData (accessToken, selectedRoles) {
        const tokens = { access_token: accessToken, external_token: this.loginData.external_token }
        this.setCaptableData(tokens, null, {}, this.userData, true, selectedRoles)
      },
      setV2CaptableOwnerData (accessToken, selectedCaptable, roles) {
        const tokens = { access_token: accessToken, external_token: this.loginData.external_token }
        this.setCaptableData(tokens, selectedCaptable.captable_id, selectedCaptable, this.userData, false, roles)
      },
      setV2HolderCaptableView (accessToken, selectedCaptable) {
        const tokens = { access_token: accessToken, external_token: this.loginData.external_token }
        this.$store.dispatch('auth/setAuthInfo', tokens)
        this.$store.dispatch('auth/setUser', this.userData)
        this.$store.dispatch('account/setUserProfile', { ...this.userData, avatar_url: '' })
        this.$store.dispatch('auth/setHolder', { ...this.userData, id: [this.userData?.id] })
        this.$store.dispatch('auth/setCurrentOrganization', selectedCaptable)
      },
    },
  }
</script>
